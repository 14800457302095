import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { Fonts } from '../../constants/fonts';

interface ButtonProps {
  inverse?: boolean;
  maxWidth?: string;
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) => (props.inverse ? Colors.white : Colors.primary)};
  color: ${(props) => (props.inverse ? Colors.primary : Colors.white)};
  border: 2px solid ${(props) => (props.inverse ? Colors.white : Colors.primary)};
  font-family: ${Fonts.base};
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  padding: 0 40px;
  min-width: 190px;
  border-radius: 6px;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${(props) => props.maxWidth ?? 'none'};
  gap: 10px;

  &:hover {
    background: ${(props) => (props.inverse ? Colors.primaryLight : Colors.primaryDark)};
    border-color: ${(props) => (props.inverse ? Colors.primary : Colors.primaryDark)};
  }

  &[disabled] {
    background: ${Colors.grey};
  }
`;
