import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { StateProvider } from './state/StateProvider';
import GlobalStyle from '../styles/global';
import { Home } from './components/Home';
import { LocalizedRoute } from './i18n/components/LocalizedRoute';

export const App: React.FC = () => (
  <StateProvider>
    <GlobalStyle />
    <BrowserRouter>
      <Routes>
        <Route
          path="/:lang"
          element={
            <LocalizedRoute>
              <Home />
            </LocalizedRoute>
          }
        />
        <Route
          path="/"
          element={
            <LocalizedRoute>
              <Home />
            </LocalizedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  </StateProvider>
);
