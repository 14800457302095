import React from 'react';

export const ExpandBannerIcon = () => (
  <svg width="12" height="43" viewBox="0 0 12 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.459029 19.4692C1.07107 18.2213 2.06338 18.2213 2.67542 19.4692L7.10819 28.5072L11.541 37.5451C12.153 38.793 12.153 40.8162 11.541 42.0641C10.9289 43.312 9.93662 43.312 9.32458 42.0641L0.459029 23.9882C-0.153009 22.7403 -0.153009 20.7171 0.459029 19.4692Z"
      fill="white"
    />
    <path
      d="M11.541 0.93591C12.153 2.18379 12.153 4.207 11.541 5.45488L2.67542 23.5308C2.06338 24.7787 1.07107 24.7787 0.459029 23.5308C-0.153009 22.2829 -0.153009 20.2597 0.459029 19.0118L9.32458 0.935911C9.93662 -0.31197 10.9289 -0.31197 11.541 0.93591Z"
      fill="white"
    />
  </svg>
);
