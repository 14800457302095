const size = {
  mobile: 500,
  tablet: 768,
  laptop: 1024,
  desktop: 1400,
  fullHd: 1918,
  xl: 2560,
};

export const Device = {
  mobile: `@media (max-width: ${size.mobile}px)`,
  tablet: `@media (max-width: ${size.tablet}px)`,
  laptop: `@media (max-width: ${size.laptop}px)`,
  desktop: `@media (max-width: ${size.desktop}px)`,
  fullHd: `@media (max-width: ${size.fullHd}px)`,
  fullHdDown: `@media (max-width: ${size.fullHd - 1}px)`,
  fullHdUp: `@media (min-width: ${size.fullHd + 1}px)`,
  xl: `@media (max-width: ${size.xl}px)`,
};
