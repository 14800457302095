import {
  DeploymentsInterface,
  FAQInterface,
  ProjectBulletPointInterface,
  ProjectInterface,
  ReferenceInterface,
  ResourcesInterface,
  TeamInterface,
  TeamMemberInterface,
} from '../../model/ProjectInterface';
import { Deployments, FAQ, Project, ProjectBulletPoint, Reference, Resources, Team, TeamMember } from '../../model/Project';
import { AppLanguage } from '../../model/enums/AppLanguage';

export const translateProject = (data: ProjectInterface, locale: string): Project => {
  const points = data.points ? data.points.map((p) => translateProjectPoint(p, locale)) : [];
  const faq = data.faq ? translateFAQ(data.faq, locale) : undefined;
  const teams = data.teams ? data.teams.map((team) => translateTeam(team, locale)) : [];
  const references = data.references.map((reference) => parseReferences(reference, locale));
  const deployments = translateDeployments(data.deployments, locale);
  const resources = translateResources(data.resources, locale);

  if (locale === AppLanguage.Czech) {
    return {
      ...data,
      name: data.name_cs ?? '',
      subtitle: data.subtitle_cs ?? '',
      about: data.about_cs ?? [],
      transition: data.transition_cs ?? [],
      contactNote: data.contact_note_cs,
      banner: data.banner_cs ?? '',
      points,
      faq,
      teams,
      documents: data.documents_cs,
      references,
      deployments,
      resources,
    };
  }

  return {
    ...data,
    name: data.name_en ?? '',
    subtitle: data.subtitle_en ?? '',
    about: data.about_en ?? [],
    transition: data.transition_en ?? [],
    contactNote: data.contact_note_en,
    banner: data.banner_en ?? '',
    documents: data.documents_en,
    points,
    faq,
    teams,
    references,
    deployments,
    resources,
  };
};

export const translateProjectPoint = (data: ProjectBulletPointInterface, locale: string): ProjectBulletPoint => {
  if (locale === AppLanguage.Czech) {
    return {
      ...data,
      title: data.title_cs,
      description: data.description_cs,
    };
  }

  return {
    ...data,
    title: data.title_en,
    description: data.description_en,
  };
};

export const translateFAQ = (data: FAQInterface, locale: string): FAQ => {
  return {
    questions: data.questions.map((q) => ({
      question: locale === AppLanguage.Czech ? q.question_cs : q.question_en,
      answer: locale === AppLanguage.Czech ? q.answer_cs : q.answer_en,
    })),
    disclaimer: locale === AppLanguage.Czech ? data.disclaimer_cs : data.disclaimer_en,
  };
};

export const translateTeam = (data: TeamInterface, locale: string): Team => {
  if (locale === AppLanguage.Czech) {
    return {
      title: data.title_cs,
      teamMembers: data.members.map((member) => translateTeamMember(member, locale)),
    };
  }

  return {
    title: data.title_en,
    teamMembers: data.members.map((member) => translateTeamMember(member, locale)),
  };
};

const translateTeamMember = (data: TeamMemberInterface, locale: string): TeamMember => {
  if (locale === AppLanguage.Czech) {
    return {
      ...data,
      name: data.name_cs,
      positions: data.positions_cs,
    };
  }

  return {
    ...data,
    name: data.name_en,
    positions: data.positions_en,
  };
};

const parseReferences = (data: ReferenceInterface, locale: string): Reference => {
  if (locale === AppLanguage.Czech) {
    return {
      name: data.name,
      photo: data.photo,
      position: data.position_cs,
      company: data.company,
      text: data.text_cs,
    };
  }
  return {
    name: data.name,
    photo: data.photo,
    position: data.position_en,
    company: data.company,
    text: data.text_en,
  };
};

const translateDeployments = (data: DeploymentsInterface, locale: string): Deployments => {
  return {
    deployments: data.deployments,
    disclaimer: locale === AppLanguage.Czech ? data.disclaimer_cs : data.disclaimer_en,
  };
};

const translateResources = (data: ResourcesInterface, locale: string): Resources => {
  if (locale === AppLanguage.Czech) {
    return {
      newsletter_note: data.newsletter_note_cs,
      handbook_note: data.handbook_note_cs,
      newsletter_button: data.newsletter_button_cs,
      handbook_button: data.handbook_button_cs,
    };
  }
  return {
    newsletter_note: data.newsletter_note_en,
    handbook_note: data.handbook_note_en,
    newsletter_button: data.newsletter_button_en,
    handbook_button: data.handbook_button_en,
  };
};
