import React from 'react';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { ReactElement } from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Section } from '../Section';
import { Container } from '../Container';
import styled from 'styled-components';
import { Button } from '../buttons/Button';
import { OutlinedButton } from '../buttons/OutlinedButton';
import { HandbookIcon } from '../icons/HandbookIcon';
import { Colors } from '../../constants/colors';
import { NewsletterIcon } from '../icons/NewsletterIcon';
import { useIsMobile } from '../../helpers/useIsMobile';
export const Resources = (props: ProjectSectionProps): ReactElement | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project) return null;

  const isMobile = useIsMobile();

  return (
    <Section id={'resources'} inverse={props.inverse}>
      <Container>
        <ResourcesContainer isMobile={isMobile}>
          {isMobile ? project.resources.newsletter_note : `${project.resources.newsletter_note}, ${project.resources.handbook_note}`}
          <ButtonsContainer isMobile={isMobile}>
            <Button onClick={() => window.open('https://share-eu1.hsforms.com/1fwb93j0IRlW6y7PAgQgimQ2b49ti', '_blank', 'noopener noreferrer')} maxWidth={'350px'}>
              <IconWrapper fill={Colors.white}>
                <NewsletterIcon />
              </IconWrapper>
              {project.resources.newsletter_button}
            </Button>
            {isMobile && project.resources.handbook_note}
            <OutlinedButton onClick={() => window.open('https://mildblue.slp.blue/verejna-prirucka', '_blank', 'noopener noreferrer')} maxWidth={'350px'}>
              <IconWrapper stroke={Colors.primary}>
                <HandbookIcon />
              </IconWrapper>
              {project.resources.handbook_button}
            </OutlinedButton>
          </ButtonsContainer>
        </ResourcesContainer>
      </Container>
    </Section>
  );
};

const ResourcesContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
`;

const ButtonsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? '20px' : '50px')};
  width: 100%;
`;

const IconWrapper = styled.div<{ fill?: string; stroke?: string }>`
  width: 30px;
  height: 30px;

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ fill }) => fill ?? 'none'};
    stroke: ${({ stroke }) => stroke ?? 'none'};
  }
`;
