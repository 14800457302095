import React, { ReactElement } from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Section } from '../Section';
import styled from 'styled-components';
import { ProjectSectionProps } from '../../model/ProjectSection';
import docIcon from '../../../assets/images/open-in-new.svg';
import { Colors } from '../../constants/colors';
import { Device } from '../../constants/device';

export const Documents = (props: ProjectSectionProps): ReactElement | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project?.documents?.length) return null;

  return (
    <Section id="documents" inverse={props.inverse}>
      <DocumentsSection>
        {project.documents.map((document) => (
          <DocumentLink title={document.title} key={document.title} href={document.url} />
        ))}
      </DocumentsSection>
    </Section>
  );
};

const DocumentsSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const DocumentLink = ({ title, href }: { title: string; href: string }) => {
  return (
    <LinkDiv>
      <a href={href} target="_blank" rel="noreferrer">
        {title}
      </a>
      <img src={docIcon} alt="Document icon" height={20} width={20} />
    </LinkDiv>
  );
};

const LinkDiv = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-right: 25px;
  padding-left: 25px;

  a {
    color: ${Colors.primary};
  }

  ${Device.mobile} {
    img {
      display: none;
    }
  }
`;
