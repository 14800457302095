import styled from 'styled-components';
import { Sizes } from '../constants/sizes';
import { Device } from '../constants/device';

interface ContainerProps {
  compact?: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => (props.compact ? Sizes.ContainerCompactWidth : Sizes.ContainerBaseWidth)}px;

  ${Device.fullHdDown} {
    max-width: ${(props) => (props.compact ? Sizes.ContainerCompactWidth : 1400)}px;
  }

  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 0 30px;
`;

Container.defaultProps = {
  compact: false,
};
